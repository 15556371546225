import React from 'react';
import FinanceBanner from '../../Images/financebanner.png';
import { Row, Col, Container, Button, Tabs, Tab } from 'react-bootstrap';
// import { BsArrowRightShort } from "react-icons/bs";
import ListingComponent from '../../components/ListingComponent';
import { Link } from 'react-router-dom';
// import FinanceBanking from "../../Images/banking.png";

const Inventory = () => {
  return (
    <>
      <div className='home'>
        <div className='home__banner home-Banners'>
          <Container>
            <Row>
              <Col md={5}>
                <h3 className='home__banner--heading'>
                  Work smarter with <span>Stock Availability</span> &{' '}
                  <span>inventory levels</span>.
                </h3>
                <p className='home__banner--paragraph'>
                  Manage sales order, suppliers, inventory levels, replenish
                  stock, shipments, deliverables - all in one open source IRD
                  verified tuna ERP.
                </p>

                <Button as={Link} to='/contact'>
                  Schedule A Demo
                </Button>
              </Col>
              <Col md={7}>
                <div>
                  <img src={FinanceBanner} alt='' />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className='homefinancetabs'>
          <Container>
            <div className='tabcontents'>
              <Tabs
                defaultActiveKey='profile'
                id='uncontrolled-tab-example'
                className='mb-3'
              >
                <Tab eventKey='profile' title='Traceability'>
                  <ListingComponent
                    heading='Traceability'
                    paragraph="Tuna ERP’s inventory feature comes in handy for maintaining a real-time view of product availability and stock across locations. ERPNext's Serialized Inventory feature comes in handy for maintaining a real-time view of product availability and stock across locations."
                  />
                </Tab>
                <Tab
                  eventKey='Avoid Inventory Storage'
                  title='Avoid Inventory Storage'
                >
                  <ListingComponent
                    heading='Avoid Inventory Storage'
                    paragraph='No need to  worry about stock dipping below a certain level. Tuna ERP brings you a set of  thresholds and automatically generates material requests to replenish stock. To prevent stockouts and grow your business, purchase Tuna ERP. '
                  />
                </Tab>
                <Tab eventKey='Quality Inspection' title='Quality Inspection'>
                  <ListingComponent
                    heading='Quality Inspection'
                    paragraph='Tuna ERP software implements quality inspections to ensure the correct stock activities of your warehouse across the orders. For better quality purchase Tuna ERP with IRD verification number. '
                  />
                </Tab>
                <Tab eventKey='Cycle Counting' title='Cycle Counting'>
                  <ListingComponent
                    heading='Cycle CountingAvoid Inventory Storage'
                    paragraph="The aim of Tuna ERP's inventory cycle counting is to identify the occurrence of inventory errors and then manage and fix the root cause of those errors. 
 "
                  />
                </Tab>
                <Tab eventKey='Unlimited Warehouse' title='Unlimited Warehouse'>
                  <ListingComponent
                    heading='Unlimited Warehouse'
                    paragraph='With Tuna ERP inventory management software you can create unlimited warehouses to keep track of not just actual warehouses but also rooms, rows, shelves, and bins. You can create a sub-Warehouse which could be a shelf inside your actual location.'
                  />
                </Tab>
              </Tabs>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Inventory;
