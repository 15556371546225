import React from "react";
import FinanceBanner from "../../Images/financebanner.png";
import { Row, Col, Container, Button, Tabs, Tab } from "react-bootstrap";
import ListingComponent from "../../components/ListingComponent";
import { Link } from "react-router-dom";
const SupplyChain = () => {
  return (
    <>
      <div className="home">
        <div className="home__banner home-Banners">
          <Container>
            <Row>
              <Col md={5}>
                <h3 className="home__banner--heading">
                  Helping unite the <span>Supply Chain</span> -one connection at a time.
                </h3>
                <p className="home__banner--paragraph">
                Tuna ERP digitizes & create high impact connections that transform your supply chain performance , efficiency and economics.

                </p>

                <Button as={Link} to="/contact">Schedule A Demo</Button>
              </Col>
              <Col md={7}>
                <div>
                  <img src={FinanceBanner} alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="homefinancetabs">
          <Container>
            <div className="tabcontents supply-planning">
              <Tabs
                defaultActiveKey="profile"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="profile" title="Supply Planning">
                 <ListingComponent heading="Supply Planning"
                 paragraph=" Tuna ERP supply chain management software brings  the entire planning process which includes distribution, manufacturing, and procurement operations according to demand forecasts, considering capacity constraints and material availability."/>
                </Tab>
                <Tab eventKey="Supplier Portal" title="Supplier Portal">
                <ListingComponent heading="Supplier Portal"
                 paragraph="Use the supplier portal to help suppliers manage themselves. The suppliers can submit requests for quotations and automatic supplier quotations, or access ongoing purchase orders and receipts."/>
                </Tab>
                <Tab
                  eventKey="Supplier Quotation"
                  title="Supplier Quotation"
                >
                   <ListingComponent heading="Supplier Quotation"
                 paragraph="A supplier quotation is a document by a potential supplier specifying the cost of goods or services they'll provide within a specified period. It may also contain terms of sale, terms of payment, and warranties."/>
                </Tab>
                <Tab eventKey="Track Serial Numbers" title="Track Serial Numbers">
                <ListingComponent heading="Track Serial numbers"
                 paragraph="You can  use a serial number to track the whole lifecycle of an item — from its procurement or manufacture to sale and delivery. Scan barcodes with your device camera for blazing fast search and quicker results."/>
                </Tab>
                <Tab eventKey="Supply Chain Analytics" title="Supply Chain Analytics">
                <ListingComponent heading="Supply Chain Analytics"
                 paragraph="Supply chains typically generate massive amounts of data. Supply chain analytics helps to make sense of all this data — uncovering patterns and generating insights."/>
                </Tab>
              </Tabs>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default SupplyChain;
