import React, { useState } from 'react';
import ContactBanner from '../../Images/contact.png';
import { Col, Row, Form, Container } from 'react-bootstrap';
import { BsAsterisk } from 'react-icons/bs';
import axios from 'axios';
const Index = () => {
  // Components
  const [status, setStatus] = useState('init');

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('loading');

    let valid = true;

    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const phoneRegex = /^(\+977-?|0)?[7-9]\d{9}$/;

    if (!name || !name.trim) {
      valid = false;
      setNameError('your name is required');
    } else if (name.length > 30) {
      valid = false;
      setNameError('Your name is too long, Please shorten to 30 letters');
    }

    if (!email || !email.trim) {
      valid = false;
      setEmailError('Please fill your email');
    } else if (!emailRegex.test(email)) {
      valid = false;
      setEmailError('Please provide a valid email!');
    }
    if (!phone || !phone.trim) {
      valid = false;
      setPhoneError('Please fill your phone');
    } else if (isNaN(phone) || !phoneRegex.test(phone)) {
      valid = false;
      setPhoneError('Please provide a valid phone!');
    }

    if (valid) {
      const data = {
        fullName: name,
        email,
        message,
        company_name: 'Tuna Erp',
        phone,
      };

      const url =
        'https://tunatech.tuna-erp.com/api/method/wesite_user_response.api.save_dynamic_contact';

      try {
        await axios.post(url, data, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        });
        setName('');
        setEmail('');
        setMessage('');
        setPhone('');
        setStatus('success');
      } catch (err) {
        setStatus('error');
      }

      if (status) {
        const timeoutId = setTimeout(() => {
          setStatus('init');
        }, 2000);

        // Cleanup the timeout to avoid memory leaks
        return () => clearTimeout(timeoutId);
      }
    } else {
      setStatus('init');
    }
  };

  return (
    <>
      <div className='contact'>
        <div className='contact__banner'>
          <div>
            <img src={ContactBanner} alt='' />
          </div>
          <div className='contact__banner--content'>
            <h3>Drop us a line, Say Hello</h3>
            <p>
              Fill out our form below. W e’ll be happy to answer any specific
              questions or general inquiries you may have.
            </p>
          </div>
        </div>
        <div className='contact__formmap'>
          <Container>
            <Row>
              <Col md={6}>
                <form
                  className='contact-hero-container-row-colright-form'
                  onSubmit={handleSubmit}
                >
                  <div className='mb-4'>
                    <Form.Label>
                      Full Name
                      <sup>
                        <BsAsterisk className='asterik' />
                      </sup>
                    </Form.Label>
                    <Form.Control
                      type='text'
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        setNameError('');
                      }}
                      placeholder='Enter your fullname'
                    />
                    {nameError && <p className='error'>{nameError}</p>}
                  </div>
                  <div className='mb-4'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type='email'
                      placeholder='Enter your email'
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setEmailError('');
                      }}
                    />
                    {emailError && <p className='error'>{emailError}</p>}
                  </div>
                  <div className='mb-4'>
                    <Form.Label>
                      Phone Number{' '}
                      <sup>
                        <BsAsterisk className='asterik' />
                      </sup>
                    </Form.Label>
                    <Form.Control
                      type='number'
                      placeholder='Enter your phonenumber'
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                        setPhoneError('');
                      }}
                    />
                    {phoneError && <p className='error'>{phoneError}</p>}
                  </div>
                  <div className='mb-4'>
                    <Form.Label>Message </Form.Label>
                    <Form.Control
                      as='textarea'
                      placeholder='Type your message here'
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                    />
                  </div>
                  <button disabled={status === 'loading'}>
                    {status === 'loading'
                      ? // <BiLoaderAlt className='animate-spin' />
                        'submitting...'
                      : 'Submitt'}
                  </button>
                  {status === 'success' && (
                    // <SuccessBox closeModal={() => setStatus("init")} />

                    <section className='success'>
                      <div>Message Sent Successfully!! Thank you !</div>
                    </section>
                  )}
                </form>
              </Col>
              <Col md={6}>
                <div>
                  <iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.547404688738!2d83.4570145500519!3d27.66947133367912!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3996857d751b77c5%3A0xa381dc824bd7487b!2sYogikuti!5e0!3m2!1sen!2snp!4v1655705848771!5m2!1sen!2snp' />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Index;
