import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GoLocation } from 'react-icons/go';
import { MdOutlineEmail, MdCall } from 'react-icons/md';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
import { BiCopyright } from 'react-icons/bi';
const Footer = () => {
  return (
    <>
      <div className='footer'>
        <Container>
          <Row>
            <Col md={2}>
              <p className='footer__title'>Company Info</p>
              <ul>
                <li>
                  <Link to='https://www.tunatechnology.com/'>
                    About Company{' '}
                  </Link>
                </li>

                <li>
                  <Link to='/erpfaq'>FAQs </Link>
                </li>
                <li>
                  <Link to='contact'>Contact </Link>
                </li>
              </ul>
            </Col>
            <Col md={5}>
              <p className='footer__title'>Solution Areas</p>
              <ul className='footer__title--solution'>
                <li>
                  <Link to='/accounting'>Finance Management </Link>
                </li>
                <li>
                  <Link to='/crm'>Customer Relation Management </Link>
                </li>
                <li>
                  <Link to='/inventory'>Inventory Management </Link>
                </li>
                <li>
                  <Link to='/humanresource'>Human Resources Management </Link>
                </li>
                <li>
                  <Link to='/supplychain'>Supply Chain Management </Link>
                </li>
                <li>
                  <Link to='/manufacturing'>Manufacturing </Link>
                </li>

                <li>
                  <Link to='/assetmanagement'>Asset Management </Link>
                </li>
              </ul>
            </Col>
            <Col md={3}>
              <p className='footer__title'>Contact Info</p>
              <ul>
                <li>
                  <MdCall />{' '}
                  <span className='ms-3'>
                    {' '}
                    (Butwal Nepal) 071-438471, 9857043895, 9857043891
                  </span>
                </li>
                <li>
                  <MdCall />{' '}
                  <span className='ms-3'> (Kathmandu, Nepal) 9857089262</span>
                </li>

                <li>
                  <MdOutlineEmail />{' '}
                  <span className='ms-3'>info@tunatechnology.com</span>
                </li>
              </ul>
            </Col>
            <Col md={2}>
              <p className='footer__title'>Social Media</p>
              <Link to='https://www.facebook.com/tunatechnology' target='_new'>
                <FaFacebookF className='footer__icon' />{' '}
              </Link>
              <Link to='https://twitter.com/tuna_technology' target='_new'>
                <FaTwitter className='footer__icon' />{' '}
              </Link>
              <Link to='https://www.instagram.com/tunatechnology' target='_new'>
                <FaInstagram className='footer__icon' />
              </Link>
            </Col>
          </Row>
          <div className='footer__copyright'>
            <p>
              Copyright <BiCopyright /> Tuna Technology{' '}
              {new Date().getFullYear()} All Right Reserved.
            </p>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Footer;
