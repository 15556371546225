import React from "react";
import FinanceBanner from "../../Images/financebanner.png";
import { Row, Col, Container, Button, Tabs, Tab } from "react-bootstrap";
import { BsArrowRightShort } from "react-icons/bs";
import { Link } from "react-router-dom";
import FinanceBanking from "../../Images/banking.png";
import finance from "../../Images/finance1.svg";
import Crm from "../../Images/managementsvg.svg";
import Inventory from "../../Images/dashboard.svg";
import HRM from "../../Images/process.svg";
import SupplyChains from "../../Images/supply-chain.svg";
import Manu from "../../Images/product-development.svg";
import Projects from "../../Images/idea.svg";
import Assetmgmnt from "../../Images/financial.svg";
import ListingComponent from "../../components/ListingComponent";
const AssetManagement = () => {
  const erpNeed = [
    {
      image: finance,
      title: "Finance",
      description:
        "Access financial, operational and data transaction from across from the  organization. ",
        link:"finance"
    },
    {
      image: Crm,
      title: "CRM",
      description:
        "Increase customer relationships, enhance customer service and boost sales.",
        link:"crm"
    },
    {
      image: Inventory,
      title: "Inventory",
      description:
        "Optimize inventory levels based on current and forecasted demand. ",
        link:"inventory"

    },
    {
      image: HRM,
      title: "Human Resource",
      description:
        "Evaluate employees record and leverage workflows that offer scalability.",
        link:"humanresource"
    },
    {
      image: SupplyChains,
      title: "Supply Chain",
      description:
        "Seamlessly connect your supply chain to outpace change and minimize costs.",
        link:"supplychain"
    },
    {
      image: Manu,
      title: "Manufactoring",
      description:
        "Effortlessly manage manufacturing process with deep functionality and breadth.",
        link:"manufacturing"
    },
    {
      image: Projects,
      title: "Projects",
      description:
        "Create and track tasks to keep your employees on their toes during important projects",
        link:"projects"
    },
    {
      image: Assetmgmnt,
      title: "Asset Management",
      description:
        "Tuna ERP covers   every branch of your organization, all in one centralized system.",
        link:"assetmanagement"
    },
  ];
  return (
    <>
      <div className="home">
        <div className="home__banner home-Banners">
          <Container>
            <Row>
              <Col md={5}>
                <h3 className="home__banner--heading">
                  Jam-packed in a single tool to <span>manage assets</span> painlessly.
                </h3>
                <p className="home__banner--paragraph">
                From purchase to perishment, IT infrastructure to equipment cover every branch of your organization, all in one Tuna ERP software .

                </p>

                <Button as={Link} to="/contact">Schedule A Demo</Button>
              </Col>
              <Col md={7}>
                <div>
                  <img src={FinanceBanner} alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="homefinancetabs">
          <Container>
            <div className="tabcontents supply-planning">
              <Tabs
                defaultActiveKey="Asset Lifecycle"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="Asset Lifecycle" title="Asset Lifecycle">
                  <ListingComponent heading="Asset Lifecycle" paragraph="In Tuna ERP, the Asset lifecycle's record is the heart of the asset management module. All the transactions related to an Asset like purchasing, depreciation, maintenance, movement, scrapping, sales will be recorded in this system."/>
                </Tab>
                <Tab eventKey="Asset Inventory" title="Asset Inventory">
                <ListingComponent heading="Asset Inventory" paragraph="With Tuna ERP system , you can maintain fixed asset records for company assets like computers, furniture, cars, etc. and manage their depreciations, sale, or disposal. You can track locations of the assets or keep records of employees who are using the asset. You can also manage the maintenance details of the assets."/>
                </Tab>
                <Tab
                  eventKey="Asset Value Adjustment"
                  title="Asset Value Adjustment"
                >
                 <ListingComponent heading="Asset Value Adjustment " paragraph="Sometimes the value of an asset needs some adjustment, this can be managed within asset value adjustment feature of Tuna ERP .The system will create journal entries and recalculate depreciation amounts."/>
                </Tab>
                <Tab eventKey="Asset Reports" title="Asset Reports">
                <ListingComponent heading="Asset Reports" paragraph="The report of assets shows the purchase amount, depreciated amount and accumulated total depreciation for all the assets under the selected date range. It also shows the current value and current depreciation status of the Asset."/>
                </Tab>
              </Tabs>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default AssetManagement;
