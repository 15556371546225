import React from 'react'
import FinanceBanner from "../../Images/financebanner.png";
import { Row, Col, Container, Button, Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import FinanceBanking from "../../Images/banking.png";
import ListingComponent from '../../components/ListingComponent';
const CRM = () => {
   
      return (
        <>
          <div className="home">
            <div className="home__banner home-Banners">
              <Container>
                <Row>
                  <Col md={5}>
                    <h3 className="home__banner--heading">
                      Nurture your customers using <span>advanced CRM</span> software.
                    </h3>
                    <p className="home__banner--paragraph">
                    Handle customers and company representatives. From a fresh lead to a regular buying customer, manage contents and support essentials for everyone. 
                    </p>
    
                    <Button as={Link} to="/contact">Schedule A Demo</Button>
                  </Col>
                  <Col md={7}>
                    <div>
                      <img src={FinanceBanner} alt="" />
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="homefinancetabs">
              <Container>
                <div className="tabcontents">
                  <Tabs
                    defaultActiveKey="home"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                    <Tab eventKey="home" title="Marketing Automation">
                      <ListingComponent heading="Marketing Automation"
                      paragraph=" Our open-source CRM tool makes your lead closure soar. Use email campaigns for targeted marketing, send bulk emails to your customers with beautiful newsletters, and reap the benefits of your campaigns with granular analysis of lead sources."/>
                    </Tab>

                    <Tab eventKey="Omnichannel Interactions" title="Omnichannel Interactions">
                    <ListingComponent heading="Omnichannel Interactions"
                      paragraph=" Tuna ERP makes it easy to convert all interactions into data so your sales team doesn't have to juggle between apps. With Call Popup, you can receive live notifications of incoming calls on your desktop. You can also turn emails in your inbox into leads with this open source CRM software."/>
                    </Tab>

                    <Tab
                      eventKey="On-Brand Custom Quotations"
                      title="On-Brand Custom Quotations"
                    >
                     <ListingComponent heading="On-Brand Custom Quotations"
                     paragraph=" Responding to customer demand, the new CRM Quotations functionality provides an all-in-one sales pipeline and quoting solution where users can manage and complete the whole sales process."/>
                    </Tab>

                    <Tab  eventKey="360-Degree View Of Your Business" title="360-Degree View Of Your Business">
                   <ListingComponent heading="360 - Degree View of Your Business"
                   paragraph="The killer feature of Tuna ERP's CRM system is its ability to give businesses, small and large, context. The notion of a 360-degree of your business drips with the promise of better understanding,enhanced customer engagement,and more sales."/>
                    </Tab>

                    <Tab  eventKey="360-Degree View Of Your Business" title="360-Degree View Of Your Business">
                   <ListingComponent heading="360 - Degree View of Your Business"
                   paragraph="The killer feature of Tuna ERP's CRM system is its ability to give businesses, small and large, context. The notion of a 360-degree of your business drips with the promise of better understanding, enhanced customer engagement, and more sales."/>
                    </Tab>
                   
                  </Tabs>
                </div>
              </Container>
            </div>
          </div>
        </>
      );
    };
    

export default CRM