import React from "react";
import FinanceBanner from "../../Images/financebanner.png";
import { Row, Col, Container, Button, Tabs, Tab } from "react-bootstrap";
import ListComponent from "../../components/ListingComponent"
import { Link } from "react-router-dom";

const Projects = () => {
      return (
        <>
          <div className="home">
            <div className="home__banner home-Banners">
              <Container>
                <Row>
                  <Col md={5}>
                    <h3 className="home__banner--heading">
                      Streamline <span>team collaboration & accelerate</span>  your work.
                    </h3>
                    <p className="home__banner--paragraph">
                    From organizing, deadlining, task prioritizing, team based assignments, chopping down work to the minimal.
                    </p>
    
                    <Button as={Link} to="/contact">Schedule A Demo</Button>
                  </Col>
                  <Col md={7}>
                    <div>
                      <img src={FinanceBanner} alt="" />
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="homefinancetabs">
              <Container>
                <div className="tabcontents">
                  <Tabs
                    defaultActiveKey="profile"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                    <Tab eventKey="profile" title="Tasks management">
                    <ListComponent heading="Tasks management"
                    paragraph="Keep track of project deadlines and progress with a clear overview. Create as many tasks and add as many users and clients you want. Completely revamp the way you manage your projects and task lists. Team collaboration has never been much easier. Everything you need to Manage your tasks."/>
                    </Tab>
                    <Tab eventKey="Time Tracking" title="Time Tracking">
                    <ListComponent heading="Time Tracking"
                    paragraph="Create milestones that your project needs . Assign messages, to-do lists and to-do’s to each milestone. Track the progress, see which are falling behind. Tuna ERP simplifies  tracking by defining actionable items — the activities for projects and tasks, based on what needs to be done. "/>
                    </Tab>
                    <Tab
                      eventKey="Document Storage"
                      title="Document Storage"
                    >
                      <ListComponent heading="Document Storage"
                    paragraph="Managing projects with all their documents can be ticklish, and momentous that everyone can access the important files. Tuna ERP management software lets you upload and share multimedia files such as images, videos, documents. You can choose to upload the files directly or use a web link."/>
                    </Tab>
                    <Tab eventKey="To-do list & real time chat" title="To-do list & real time chat">
                    <ListComponent heading="To-do list and real time chat"
                    paragraph="With Tuna ERP's project management system you can automatically add the tasks in their to-dos to keep assignments and task-tracking hassle-free. And with mentions and real-time chat, there's no need to bring external chats into internal communication."/>
                    </Tab>
                    <Tab eventKey="Project Reports" title="Project Reports">
                    <ListComponent heading="Project Reports"
                    paragraph="Project reports help you analyze the billing per Project and Employee between a specific period. With this system you can also track the various income and expenses while the development happens on the projects. 
                    "/>
                    </Tab>
                  </Tabs>
                </div>
              </Container>
            </div>
          </div>
        </>
      );
    };

export default Projects