import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Navbbar from '../../components/Navbbar';
import Footer from '../../components/Footer';
import Home from '../Home';
import Contact from '../Contact';
import ERPFAQ from '../ERPFAQ';
import Finance from '../Finance';
import CRM from '../CRM';
import Inventory from '../Inventory';
import Projects from '../Projects';
import HumanResources from '../HumanResources';
import SupplyChain from '../SupplyChain';
import Manufacturing from '../Manufacturing';
import AssetManagement from '../AssetManagement';

const App = () => {
  return (
    <>
      <Navbbar />

      <main>
        <section className='contentWrapper'>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/erpfaq' element={<ERPFAQ />} />
            <Route path='/accounting' element={<Finance />} />
            <Route path='/crm' element={<CRM />} />
            <Route path='/inventory' element={<Inventory />} />
            <Route path='/project' element={<Projects />} />
            <Route path='/humanresource' element={<HumanResources />} />
            <Route path='/supplychain' element={<SupplyChain />} />
            <Route path='/manufacturing' element={<Manufacturing />} />
            <Route path='/assetmanagement' element={<AssetManagement />} />
          </Routes>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default App;
