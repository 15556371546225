import React from "react";
import FinanceBanner from "../../Images/financebanner.png";
import { Row, Col, Container, Button, Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import ListingComponent from "../../components/ListingComponent";
const SupplyChain = () => {
  return (
    <>
      <div className="home">
        <div className="home__banner home-Banners">
          <Container>
            <Row>
              <Col md={5}>
                <h3 className="home__banner--heading">
                  MOve beyond smart tools with <span>Manufacturing</span> Tuna
                  ERP software.
                </h3>
                <p className="home__banner--paragraph">
                  Tuna ERP effectively maintain and manage multilevel bill of
                  materials, production planning , job cards and inventory.
                </p>

                <Button as={Link} to="/contact">
                  Schedule A Demo
                </Button>
              </Col>
              <Col md={7}>
                <div>
                  <img src={FinanceBanner} alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="homefinancetabs">
          <Container>
            <div className="tabcontents supply-planning">
              <Tabs
                defaultActiveKey="profile"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab
                  eventKey="profile"
                  title="Bill of Material (BOM)"
                >
                  <ListingComponent heading="Bill of Material (BOM)" paragraph=" Bill of material (BOM) ensures robustness and accuracy. Tuna ERP’s manufacturing software  includes product codes, part descriptions, quantities, costs and additional specifications." />
                </Tab>
                <Tab eventKey="Capacity planning" title="Capacity planning">
                  <ListingComponent heading="Capacity Planning" paragraph="Identify and eliminate bottlenecks at the shop-floor level by creating a capacity plan with manufacturing Tuna ERP software. Optimize your workstations based on the resources available for open production order. " />
                </Tab>
                <Tab
                  eventKey="Shop floor management"
                  title="Shop floor management"
                >
                  <ListingComponent heading="Shop Floor Management" paragraph="View real-time status of the job work being done to manufacture products, the location of every workstation, employee assignments, and each work order's current status and pending steps — all in one screen with our open source tuna ERP software. " />
                </Tab>
                <Tab
                  eventKey="Material resource planning"
                  title="Material resource planning"
                >
                  <ListingComponent heading="Material Resource Planning" paragraph="With the help of the Tuna ERP's MRP take your production to the next level and create a more efficient production and resource flow. Track material consumption of serialized or batched inventory to curb expenses and achieve zero wasted." />
                </Tab>
              </Tabs>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default SupplyChain;
