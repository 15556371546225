import React from 'react';
import HomeBanner from '../../Images/Banner.png';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { BsArrowRightShort } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import Finance from '../../Images/finance1.svg';
import Crm from '../../Images/managementsvg.svg';
import Inventory from '../../Images/dashboard.svg';
import HRM from '../../Images/process.svg';
import SupplyChain from '../../Images/supply-chain.svg';
import Manu from '../../Images/product-development.svg';
import Projects from '../../Images/idea.svg';
import Assetmgmnt from '../../Images/financial.svg';
import VerifiedPana from '../../Images/Verified-pana.png';
import BetterDecisions from '../../Images/womenthinking.png';
import { TiTick } from 'react-icons/ti';
import { FaQuoteLeft } from 'react-icons/fa';
import RunBusiness from '../../Images/runbusiness.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
const Home = () => {
  const erpNeed = [
    {
      image: Finance,
      title: 'Finance',
      description:
        'Access financial, operational and data transaction from across from the  organization. ',
      link: 'finance',
    },
    {
      image: Crm,
      title: 'CRM',
      description:
        'Increase customer relationships, enhance customer service and boost sales.',
      link: 'crm',
    },
    {
      image: Inventory,
      title: 'Inventory',
      description:
        'Optimize inventory levels based on current and forecasted demand. ',
      link: 'inventory',
    },
    {
      image: HRM,
      title: 'Human Resource',
      description:
        'Evaluate employees record and leverage workflows that offer scalability.',
      link: 'humanresource',
    },
    {
      image: SupplyChain,
      title: 'Supply Chain',
      description:
        'Seamlessly connect your supply chain to outpace change and minimize costs.',
      link: 'supplychain',
    },
    {
      image: Manu,
      title: 'Manufactoring',
      description:
        'Effortlessly manage manufacturing process with deep functionality and breadth.',
      link: 'manufacturing',
    },
    {
      image: Projects,
      title: 'Projects',
      description:
        'Create and track tasks to keep your employees on their toes during important projects',
      link: 'project',
    },
    {
      image: Assetmgmnt,
      title: 'Asset Management',
      description:
        'Tuna ERP covers   every branch of your organization, all in one centralized system.',
      link: 'assetmanagement',
    },
  ];
  const testimonials = [
    {
      para: "Our experience with the Tuna ERP system has been nothing short of transformative. As a mid-sized manufacturing company, we struggled with disparate systems and inefficiencies in our operations. Since implementing Tuna ERP, we've seen a remarkable improvement in productivity and a significant reduction in operational costs. The real-time data and analytics provided by the system have enabled us to make more informed decisions, enhancing our overall business strategy. The support team has been exceptional, guiding us through the entire process and ensuring a smooth transition. We highly recommend Tuna ERP to any business looking to streamline their operations and drive growth.",
      name: 'Pratit Lal Makaju',
      post: 'Business Development Officer',
    },
    {
      para: "Choosing Tuna ERP was one of the best decisions we made for our retail business. The cloud-based system's scalability and accessibility have been game-changers for us, allowing our team to access critical information from anywhere. The implementation process was seamless, thanks to the professional and dedicated support from Tuna ERP's team. We've seen a noticeable increase in efficiency, and our customer service has improved significantly due to better management of customer interactions. The regular updates and security measures give us peace of mind, knowing our data is always protected and up-to-date. Tuna ERP has truly exceeded our expectations.",
      name: 'Dinesh Basnet',
      post: 'Factory Manager',
    },
    {
      para: "As a growing logistics company, we needed an ERP system that could keep up with our dynamic and evolving needs. Tuna ERP has delivered beyond our expectations. The system's ability to integrate various business functions into a single platform has streamlined our processes and improved our workflow. The real-time insights and analytics have been invaluable in optimizing our operations and making strategic decisions. The support and maintenance provided are top-notch, with regular updates and prompt assistance whenever needed. Tuna ERP has not only met but surpassed our requirements, and we are thrilled with the positive impact it has had on our business.",
      name: 'Bishal Bista',
      post: 'Factory manager',
    },
  ];
  const settings = {
    infinite: true,
    autoplaySpeed: 2000,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className='home'>
        <div className='home__banner'>
          <Container>
            <Row>
              <Col md={5}>
                <h3 className='home__banner--heading'>
                  Autopilot your business with our <span>IRD VERIFIED ERP</span>{' '}
                  solution.
                </h3>
                <p className='home__banner--paragraph'>
                  Manage accounting, inventory, operations, distribution, Human
                  Rresource and more from a single application.
                </p>

                <Button to='/contact' as={Link}>
                  Schedule A Demo
                </Button>
              </Col>
              <Col md={7}>
                <div>
                  <img src={HomeBanner} alt='' />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className='home__erpneed'>
          <h4>ERP For Every Need</h4>
          <p className='home__erpneed--subtitle'>
            Automate manual processes and outpace the competition.
          </p>
          <Container>
            <Row className='gy-5'>
              {erpNeed.map((curElm, index) => {
                return (
                  <Col lg={3} md={6} sm={6} key={index}>
                    <img src={curElm.image} alt='' />
                    <p className='home__erpneed--subtitle-needtitle'>
                      {curElm.title}
                    </p>
                    <p className='home__erpneed--subtitle-desc'>
                      {curElm.description}
                    </p>
                    <Link to={`/${curElm.link}`}>
                      Learn More <BsArrowRightShort />
                    </Link>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </div>
        <div className='home__irdverified'>
          <Container>
            <Row>
              <Col md={7}>
                <h4>IRD verified softwares to sell your services</h4>
                <p>
                  Our suite of IRD(Inland Revenue Department) verified tools is
                  tailored to your business needs so you can streamline
                  operations and collaborate effectively. In short, it connects
                  the dots, packaging data into one simple system.
                </p>
                <p>
                  From planning to integration, we will help you get up and
                  running with our verified ERP solution at a price that works.
                </p>
              </Col>
              <Col md={1}></Col>
              <Col md={4}>
                <div>
                  <img src={VerifiedPana} alt='' className='img-fluid' />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className='home__betterdecisions'>
          <Container>
            <Row>
              <Col md={4}>
                <div>
                  <img src={BetterDecisions} alt='' />
                </div>
              </Col>
              <Col md={2}></Col>
              <Col md={6}>
                <h4>Make Better Decisions With Tuna ERP</h4>
                <p>
                  Tuna Technology explores why custom ERP software is cost
                  effective, tracking business performance and improving
                  customer experience.
                </p>
                <ul>
                  <li>
                    <TiTick className='me-3' />
                    Efficient and accurate inventory management.
                  </li>
                  <li>
                    <TiTick className='me-3' />
                    Deliver productivity and agility.
                  </li>
                  <li>
                    <TiTick className='me-3' />
                    Optimize collaboration & better customer service.
                  </li>
                  <li>
                    <TiTick className='me-3' />
                    Accelerate time to value.
                  </li>
                </ul>
                <Button as={Link} to='/contact'>
                  Get an Intro Call
                </Button>
              </Col>
            </Row>
          </Container>
        </div>

        <div className='home__runbusiness'>
          <Container>
            <Row>
              <Col md={7}>
                <h4>Run a Business With Ease</h4>
                <p>
                  With Tuna ERP you are always on top of what's happening in
                  your business. Get real-time insights into every aspect of
                  your company's performance and discover new ways to optimize
                  the processes with ERP software.{' '}
                </p>
                <p>
                  We offer powerful solutions that are more efficient and
                  cost-effective which can help you manage your day-to-day
                  operations. Once you implement the ERP system you’ll be able
                  to identify areas that need improvement and make better
                  decisions.
                </p>
                <p>
                  Let us worry about the implementation, so you can focus on
                  better serving your customers.{' '}
                </p>
              </Col>
              <Col md={1}></Col>
              <Col md={4}>
                <div>
                  <img src={RunBusiness} alt='' />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className='home__readerp'>
          <h4>Ready To Build ERP System?</h4>
          <p>
            Join with us and revolutionize your process and profits. Tuna ERP
            solution connect your business and drive value.
          </p>
          <Button as={Link} to='/contact'>
            Schedule a Demo
          </Button>
        </div>

        <div className='home__testimonials'>
          <Container>
            <h4>What Our Client Say</h4>
            <p className='home__testimonials--para'>
              We put our customers first by creating experiences. 97.5% of our
              clients would recommend our services to others.
            </p>

            <Slider {...settings}>
              {testimonials.map((curElm, index) => {
                return (
                  <div className='home__testimonials--content' key={index}>
                    <FaQuoteLeft className='home__testimonials--content-quote' />
                    <p className='home__testimonials--content-para'>
                      {curElm.para}
                    </p>
                    <p className='home__testimonials--content-name'>
                      {curElm.name}
                    </p>
                    <p className='home__testimonials--content-post'>
                      {curElm.post}
                    </p>
                  </div>
                );
              })}
            </Slider>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Home;
