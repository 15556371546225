import React, { useState, useRef, useEffect } from 'react';
import { Container, Navbar, Nav, Row, Col } from 'react-bootstrap';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import TunaERP from '../Images/erplogo.png';
import { GiHamburgerMenu } from 'react-icons/gi';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { ImCross } from 'react-icons/im';
const Navbbar = () => {
  const [show, setShow] = useState(false);
  const [smallShow, setSmallShow] = useState(false);
  const [smallnavbar, setSmallNavbar] = useState(false);
  const closing = () => {
    setSmallShow(false);
    setSmallNavbar(false);
  };
  const navbarDropdownList = [
    {
      title: 'Accounting',
      list: [
        {
          link: 'finance',
          listing: [
            'Banking',
            'Auto-Invoicing',
            'Billing & Pricing',
            'Chart of Accounts',
            'Journals & Payments',
            'Finance Reporting',
          ],
        },
      ],
    },
    {
      title: 'CRM',
      list: [
        {
          link: 'crm',
          listing: [
            'Marketing Automation',
            'Omnichannel Interactions',
            'On-Brand Custom Quotations',
            'Real-time View of Sales Pipeline',
            '360-Degree View Of Your Business',
          ],
        },
      ],
    },
    {
      title: 'INVENTORY',
      list: [
        {
          link: 'inventory',
          listing: [
            'Traceability',
            'Avoid Inventory Storage',
            'Quality Inspection ',
            'Cycle Counting ',
            'Unlimited Warehouse ',
          ],
        },
      ],
    },
    {
      title: 'Projects',
      list: [
        {
          link: 'project',
          listing: [
            'Tasks management',
            'Time Tracking',
            'Document Storage ',
            'To-do list & real time chat  ',
            'Project Reports ',
          ],
        },
      ],
    },
    {
      title: 'HUMAN RESOURCES',
      list: [
        {
          link: 'humanresource',
          listing: [
            'Performance Management',
            'Leave Management',
            'Payroll Period & Tax slabs ',
            'Automatic Attendence',
            'Expense Management',
          ],
        },
      ],
    },
    {
      title: 'SUPPLY CHAIN',
      list: [
        {
          link: 'supplychain',
          listing: [
            'Supply Planning ',
            'Supplier Portal ',
            'Supplier Quotation',
            'Track Serial Numbers',
            'Supply Chain Analytics',
          ],
        },
      ],
    },
    {
      title: 'CRM',
      list: [
        {
          link: 'manufacturing',
          listing: [
            'Bill of Material (BOM) ',
            'Capacity planning',
            'Shop floor management',
            'Material resource planning',
          ],
        },
      ],
    },
    {
      title: 'INVENTORY',
      list: [
        {
          link: 'assetmanagement',
          listing: [
            'Asset Lifecycle',
            'Asset Inventory ',
            'Asset Value Adjustment',
            'Asset Reports',
          ],
        },
      ],
    },
  ];
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (show && ref.current && !ref.current.contains(e.target)) {
        setShow(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [show]);

  return (
    <>
      <Navbar expand='md'>
        <Container>
          <Navbar.Brand to='/' as={Link}>
            <img src={TunaERP} alt='' />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='ms-auto'>
              <Nav.Link
                to='/'
                as={NavLink}
                onClick={() => setShow(!show)}
                className='active-link'
              >
                {show ? (
                  <span onClick={() => setShow(!show)}>
                    Solution
                    <BiChevronUp
                      style={{ fontSize: '24px', marginLeft: '10px' }}
                    />
                  </span>
                ) : (
                  <>
                    Solution
                    <BiChevronDown
                      onClick={() => setShow(!show)}
                      style={{ fontSize: '24px', marginLeft: '10px' }}
                    />
                  </>
                )}
              </Nav.Link>
              <Nav.Link to='/erpfaq' as={NavLink} className='active-link'>
                ERP FAQs
              </Nav.Link>
              <Nav.Link to='/contact' as={NavLink} className='active-link'>
                Contact
              </Nav.Link>
              <a
                href='https://tunatechnology.com'
                target='_blank'
                className='active-link mt-2'
              >
                Company
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {show ? (
        <>
          <Container ref={ref}>
            <div className='navbarDropdown'>
              <div className='navbarDropdown__arrowup'></div>
              <Row>
                {navbarDropdownList.map((curElm, index) => {
                  return (
                    <Col md={6} lg={3} sm={6} key={index}>
                      <p className='navbarDropdown__heading'>{curElm.title}</p>
                      <ul>
                        {curElm.list?.map((item, index) => {
                          return (
                            <li key={index}>
                              {item.listing?.map((b, index) => {
                                return (
                                  <Link
                                    key={index}
                                    to={`/${item.link}`}
                                    onClick={() => setShow(false)}
                                  >
                                    {b} <br />
                                  </Link>
                                );
                              })}
                            </li>
                          );
                        })}
                      </ul>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </Container>
        </>
      ) : (
        ''
      )}
      <Container>
        <div className='smallnavbar'>
          <div className='smallnavbar__image'>
            <Link to='/'>
              <img src={TunaERP} alt='' />
            </Link>
          </div>
          <div>
            <GiHamburgerMenu
              className='smallnavbar__openicon'
              onClick={() => setSmallNavbar(!smallnavbar)}
            />

            {smallnavbar && (
              <div className='smallnavbar__display'>
                <Link to='/'>
                  <img src={TunaERP} alt='' />
                </Link>
                <ImCross
                  onClick={() => setSmallNavbar(false)}
                  className='smallnavbar__display--crossicon'
                />

                <ul className='smallnavbar__display--mainul'>
                  <li>
                    <Link to='' onClick={() => setSmallShow(!smallShow)}>
                      Solution{' '}
                    </Link>
                    {show ? (
                      <BiChevronUp
                        onClick={() => setSmallShow(!smallShow)}
                        style={{
                          fontSize: '24px',
                          marginLeft: '10px',
                          color: '#24BCAA',
                        }}
                      />
                    ) : (
                      <BiChevronDown
                        onClick={() => setSmallShow(!smallShow)}
                        style={{
                          fontSize: '24px',
                          marginLeft: '10px',
                          color: '#24BCAA',
                        }}
                      />
                    )}
                    {smallShow && (
                      <ul className='smallnavbar__display--mainul-subul'>
                        <li>
                          <Link to='/finance' onClick={closing}>
                            Finance
                          </Link>
                        </li>
                        <li>
                          <Link to='/crm' onClick={closing}>
                            CRM
                          </Link>
                        </li>
                        <li>
                          <Link to='/inventory' onClick={closing}>
                            Inventory
                          </Link>
                        </li>
                        <li>
                          <Link to='/project' onClick={closing}>
                            Projects
                          </Link>
                        </li>
                        <li>
                          <Link to='/humanresource' onClick={closing}>
                            Human Resources
                          </Link>
                        </li>
                        <li>
                          <Link to='/supplychain' onClick={closing}>
                            Supply Chain
                          </Link>
                        </li>
                        <li>
                          <Link to='/manufacturing' onClick={closing}>
                            Manufacturing
                          </Link>
                        </li>
                        <li>
                          <Link to='/assetmanagement' onClick={closing}>
                            AssetManagement
                          </Link>
                        </li>
                      </ul>
                    )}
                  </li>
                  <li>
                    <Link to='/erpfaq' onClick={() => setSmallNavbar(false)}>
                      ERP FAQ
                    </Link>
                  </li>
                  <li>
                    <Link to='/contact' onClick={() => setSmallNavbar(false)}>
                      Contact
                    </Link>
                  </li>
                  <li>
                    <a
                      href='https://tunatechnology.com'
                      target='_blank'
                      className='active-link'
                    >
                      Company
                    </a>{' '}
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default Navbbar;
