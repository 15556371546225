import React from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BsArrowRightShort } from 'react-icons/bs';
import FinanceBanking from '../Images/banking.png';
import finance from '../Images/finance1.svg';
import Crm from '../Images/managementsvg.svg';
import Inventory from '../Images/dashboard.svg';
import HRM from '../Images/process.svg';
import SupplyChain from '../Images/supply-chain.svg';
import Manu from '../Images/product-development.svg';
import Projects from '../Images/idea.svg';
import Assetmgmnt from '../Images/financial.svg';

const ListingComponent = ({ heading, paragraph, image }) => {
  const erpNeed = [
    {
      image: finance,
      title: 'Finance',
      description:
        'Access financial, operational and data transaction from across from the  organization. ',
      link: 'finance',
    },
    {
      image: Crm,
      title: 'CRM',
      description:
        'Increase customer relationships, enhance customer service and boost sales.',
      link: 'crm',
    },
    {
      image: Inventory,
      title: 'Inventory',
      description:
        'Optimize inventory levels based on current and forecasted demand. ',
      link: 'inventory',
    },
    {
      image: HRM,
      title: 'Human Resource',
      description:
        'Evaluate employees record and leverage workflows that offer scalability.',
      link: 'humanresource',
    },
    {
      image: SupplyChain,
      title: 'Supply Chain',
      description:
        'Seamlessly connect your supply chain to outpace change and minimize costs.',
      link: 'supplychain',
    },
    {
      image: Manu,
      title: 'Manufactoring',
      description:
        'Effortlessly manage manufacturing process with deep functionality and breadth.',
      link: 'manufacturing',
    },
    {
      image: Projects,
      title: 'Projects',
      description:
        'Create and track tasks to keep your employees on their toes during important projects',
      link: 'project',
    },
    {
      image: Assetmgmnt,
      title: 'Asset Management',
      description:
        'Tuna ERP covers   every branch of your organization, all in one centralized system.',
      link: 'assetmanagement',
    },
  ];
  return (
    <>
      <h4 className='navtab-heading'>{heading}</h4>
      <p className='navtab-paragraph'>{paragraph}</p>
      <div>
        <img src={image} alt='' className='img-fluid' />
      </div>
      <div className='home__erpneed erpnee-bankingfinance'>
        <h4>ERP For Every Need</h4>
        <p className='home__erpneed--subtitle'>
          Automate manual processes and outpace the competition.
        </p>
        <Container>
          <Row className='gy-5'>
            {erpNeed.map((curElm, index) => {
              return (
                <Col md={3} key={index}>
                  <img src={curElm.image} alt='' />
                  <p className='home__erpneed--subtitle-needtitle'>
                    {curElm.title}
                  </p>
                  <p className='home__erpneed--subtitle-desc'>
                    {curElm.description}
                  </p>
                  <Link to={`/${curElm.link}`}>
                    Learn More <BsArrowRightShort />
                  </Link>
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>

      <div className='home__readerp'>
        <h4>Ready To Build ERP System?</h4>
        <p>
          Join with us and revolutionize your process and profits. Tuna ERP
          solution connect your business and drive value.
        </p>
        <Button as={Link} to='/contact'>
          Schedule a Demo
        </Button>
      </div>
    </>
  );
};

export default ListingComponent;
