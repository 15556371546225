import React from 'react';
import FAQBanner from '../../Images/faqbanner.png';
import { Container, Row, Col, Accordion, Form, Button } from 'react-bootstrap';
import { BsAsterisk } from 'react-icons/bs';
const ERPFAQ = () => {
  const accordinarray = [
    {
      title: 'Enterprise Resource Planning (ERP)',
      mainquestion: [
        {
          question: 'What is an ERP?',
          answer:
            'An ERP (Enterprise Resource Planning) system is a software platform that integrates various business processes and functions into a single unified system. It helps organizations streamline operations, improve efficiency, and gain insights into their business by providing a centralized database and real-time data.',
        },
        {
          question: 'How can my business benefit from an ERP system?',
          answer:
            'Implementing an ERP system can significantly benefit your business by improving overall efficiency and productivity through streamlined processes. It provides real-time data and analytics that enhance decision-making and foster better collaboration across departments. Moreover, an ERP system can help reduce operational costs by eliminating redundant tasks and improving customer service through better management of customer interactions and information.',
        },
        {
          question: 'What are the advantages of cloud based Tuna ERP system?',
          answer:
            'The cloud-based Tuna ERP system offers several advantages, including scalability to accommodate business growth and accessibility from any location with an internet connection. It is cost-effective as it reduces the need for upfront hardware and maintenance costs. The system ensures regular, automatic updates managed by the provider, which means you always have the latest features and security measures to protect your data.',
        },
      ],
    },

    {
      title: 'Implementation',
      mainquestion: [
        {
          question: 'How long does it take to implement an ERP System?',
          answer:
            'The implementation time for an ERP system varies based on the size and complexity of the business, typically ranging from a few months to a year. Factors such as the level of customization, data migration requirements, and the extent of user training needed can influence the timeline. Proper planning and resource allocation are crucial to ensuring a smooth and timely implementation.',
        },
        {
          question:
            'What hardware is required to implement an ERP System?How can my business benefit from an ERP system?',
          answer:
            'To implement an ERP system, you will need servers to host the ERP software if opting for an on-premise solution, along with computers or workstations for users to access the system. A robust network infrastructure is essential to ensure seamless connectivity, and mobile devices may be required for remote access. The specific hardware requirements can vary depending on the chosen ERP system and deployment model.',
        },
      ],
    },

    {
      title: 'Support and Maintenance',
      mainquestion: [
        {
          question: 'How is a customer supported after implementation?',
          answer:
            'Post-implementation support for customers typically includes helpdesk support for troubleshooting technical issues, regular system updates and patches, and ongoing training sessions to familiarize users with new features and best practices. Dedicated account managers or support teams are often assigned to ensure continuous assistance, complemented by online resources such as knowledge bases, forums, and tutorials.',
        },
        {
          question: 'How does it handle software updates?',
          answer:
            'Software updates for ERP systems are generally managed by the provider. These updates can be automatic, ensuring that the system is always up-to-date with the latest features and security enhancements. Providers usually schedule maintenance windows for major updates and notify users in advance. Support is available to address any issues that may arise during or after the update process.',
        },
        {
          question: 'What type of maintenance does it offer for ERP customers?',
          answer:
            'Maintenance for ERP customers typically includes regular system health checks, performance monitoring, and optimization. Security updates and vulnerability management are prioritized to protect data integrity. Additionally, providers offer backup and disaster recovery services, and customization and integration support to ensure the ERP system continues to meet the evolving needs of the business.',
        },
      ],
    },
    {
      title: 'ERP Pricing and Billing',
      mainquestion: [
        {
          question: 'Does it require a long-term contract?',
          answer:
            'If you wish to use our software, then it requires a contract depending on how long you will use it.',
        },
        {
          question: 'Are there any hidden costs we should aware of?',
          answer: 'No, there will not be any hidden costs. ',
        },
        {
          question: 'How do we pay our bill?',
          answer:
            'ERP system billing can be handled through various methods, including monthly or annual subscription payments, automated electronic funds transfer (EFT) or credit card payments, and invoicing for larger enterprises with net payment terms. Many ERP vendors provide online payment portals to facilitate convenient and secure bill payment processes.',
        },
      ],
    },
  ];
  return (
    <>
      <div className='erpfaq'>
        <div className='erpfaq__banner'>
          <img src={FAQBanner} alt='' />
        </div>
        <div className='erpfaq__banner--content'>
          <h3>ERP FAQs - We’re always ready to help.</h3>
          <p>
            Below you will find answers to the questions we get asked about ERP.
            If you have any questions that we haven't been able to answer,
            please visit our contact page.{' '}
          </p>
        </div>

        <div className='erpfaq__faq'>
          <Container>
            <Row className='gy-5'>
              {accordinarray.map((a, index) => {
                return (
                  <Col md={6} key={index}>
                    <p>{a.title}</p>
                    <Accordion>
                      {a.mainquestion.map((b, index) => {
                        return (
                          <Accordion.Item eventKey={index} key={index}>
                            <Accordion.Header>{b.question}</Accordion.Header>
                            <Accordion.Body>
                              {b.answer}
                              {b.heading}

                              {
                                <ul className='accordinerpfaq-list'>
                                  {b.answered?.map((x, index) => {
                                    return <li key={index}>{x}</li>;
                                  })}
                                </ul>
                              }
                              {b.headdingg?.map((g, index) => {
                                return (
                                  <div key={index}>
                                    <h4>{g.title}</h4>
                                    {
                                      <ul className='minimum-recommended'>
                                        {g.titlelist.map((f, index) => {
                                          return <li key={index}>{f}</li>;
                                        })}
                                      </ul>
                                    }
                                  </div>
                                );
                              })}
                            </Accordion.Body>
                          </Accordion.Item>
                        );
                      })}
                    </Accordion>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </div>
        <div className='erpfaq__form'>
          <Container>
            <h4>Didn’t find an answer?</h4>
            <p>Send us a note. Our team will get in touch with you shortly.</p>
            <Row>
              <Col md={4}>
                <div className='mb-4'>
                  <Form.Label>
                    Full Name
                    <sup>
                      <BsAsterisk className='asterik' />
                    </sup>
                  </Form.Label>
                  <Form.Control type='text' placeholder='Enter your fullname' />
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type='email'
                    placeholder='Enter your email address'
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <Form.Label>
                    Phone Number
                    <sup>
                      <BsAsterisk className='asterik' />
                    </sup>
                  </Form.Label>
                  <Form.Control
                    type='number'
                    placeholder='Enter your phone number'
                  />
                </div>
              </Col>
            </Row>
            <div className='mb-4'>
              <Form.Label>
                Message{' '}
                <sup>
                  <BsAsterisk className='asterik' />
                </sup>
              </Form.Label>
              <Form.Control
                as='textarea'
                placeholder='Please specify what you are looking for?'
                rows={5}
              />
            </div>
            <Button>Submit</Button>
          </Container>
        </div>
      </div>
    </>
  );
};

export default ERPFAQ;
