import React from "react";
import FinanceBanner from "../../Images/financebanner.png";
import { Row, Col, Container, Button, Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import ListingComponent from "../../components/ListingComponent";

const Finance = () => {
  
  return (
    <>
      <div className="home">
        <div className="home__banner home-Banners">
          <Container>
            <Row>
              <Col md={5}>
                <h3 className="home__banner--heading">
                  <span>IRD Verified HRMS & Payroll</span> software for your
                  growing business.
                </h3>
                <p className="home__banner--paragraph">
                  Tuna ERP makes it easy to manage the full employee life cycle
                  right from onboarding, payroll , attendance, expense claims.
                </p>

                <Button as={Link} to="/contact">
                  Schedule A Demo
                </Button>
              </Col>
              <Col md={7}>
                <div>
                  <img src={FinanceBanner} alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="homefinancetabs">
          <Container>
            <div className="tabcontents">
              <Tabs
                defaultActiveKey="profile"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab
                  eventKey="profile"
                  title="Performance Management"
                >
                  <ListingComponent heading="Performance Management" paragraph=" In Tuna ERP , you can keep an eye on employees journeys and process salary hikes and bonus for top performers. This system can be used to track the performance of each user. Manage all this professionally , purchase tuna ERP." />
                </Tab>
                <Tab eventKey="Leave Management" title="Leave Management">
                  <ListingComponent heading="Leave Management " paragraph=" Manage employee leaves based on calendar year or fiscal year. Use the Leave Period to set start and end dates for allocated leaves. Just input weekends, set the number of working hours, grant permission to transfer leaves and more, all within a few clicks." />
                </Tab>
                <Tab
                  eventKey="Payroll Period & Tax slabs"
                  title="Payroll Period & Tax slabs"
                >
                  <ListingComponent heading="Payroll Period and Tax Slabs" paragraph="Payroll Period helps you define Salary Structures and to calculate tax for a specific period based on applicable Income Tax Slab. Define the period for payroll processing and set tax salary slabs for employees." />
                </Tab>
                <Tab
                  eventKey="Automatic Attendence"
                  title="Track Time and Attendance "
                >
                  <ListingComponent heading="Track Time and Attendance " paragraph="Tuna ERP's attendance tool includes multiple options for capturing real-time attendance, including biometric hardware integration. It also allows you to quickly add daily attendance records for multiple employees based on their department and branch." />
                </Tab>
                <Tab eventKey="Expense Management" title="Expense Management">
                  <ListingComponent heading="Expense Management" paragraph="Manage expenses and employee advances with our open source HRMS & payroll software. Our Expense management system helps managers to see all employee expenses in one click and easy configurable workflows saves the manual effort of managing expenses." />
                </Tab>
              </Tabs>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Finance;
